import './index.less';
import './index.html';
import 'public/main.js';
import $ from 'jquery';
import 'public/plus/jsmodern/jsmodern.min.css';
import 'public/plus/jsmodern/jsmodern.min.js';
import doc1 from 'public/assets/files/申请程序.doc';
import doc2 from 'public/assets/files/调查问卷.doc';
import doc3 from 'public/assets/files/同意个人数据处理.doc';
import mp4 from 'public/assets/files/colors.mp4';

$(async function() {
    let items = $('.customer-list .list-item');
    items[0].href = doc1;
    items[1].href = doc2;
    items[2].href = doc3;

    // let video = $('#video video').attr('src', mp4).on('canplaythrough', function() {
    //     // video[0].muted = false;
    //     video[0].play(); 
    //     // setTimeout(function() {
            
    //     // });
    // });
    // document.body.addEventListener('mousedown', function(){
    //     video[0].muted = false;
    // }, false);
    $('#video video').attr('src', mp4);
    jsModern.video("#video");

    $('#maskLoad').hide();
});