import 'fonts/iconfont.css';
import 'css/reset.less';
import 'css/class.less';
import 'css/common.less';
import 'css/animate.less';
require('expose-loader?$!jquery');
window.jQuery = $;

// hot替换
if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept();
};